@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(13, 17, 23, 0.3);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(61, 78, 106, 0.5);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(61, 78, 106, 0.8);
}




body {


  background: linear-gradient(90deg,


      rgba(67, 49, 101, 1) 0%,


      rgba(16, 22, 31, 1) 35%,


      rgba(26, 32, 60, 1) 100%);



}




.text-gradient {


  background-clip: text;


  -webkit-background-clip: text;


  color: transparent;

}




@keyframes borderFlash {





  0%,


  100% {


    border-color: #775dda;


    /* Primary color */


  }




  50% {


    border-color: #968e9ea0;


    /* Secondary color */


  }

}




.border-flash {


  animation: borderFlash 2s infinite;


}




.border-flash:hover {


  animation: none;


  border-color: #775dda;


  /* Maintain primary color on hover */

}